import {sha256} from "js-sha256";

export const commandHandler = (contextStore, frontendApi, backendApi, secretKey, accountId, apiHost) => {
    const contextToActivityData = contextDTO => {
        return {
            customer_id_hash: contextDTO.customer_id_hash || null,
            store: contextDTO.store || "default",
            currency: contextDTO.store || "USD",
            environment: contextDTO.store || "default",
            price_list: contextDTO.price_list || "default",
        };
    }

    const uniqueId = () => Math.random().toString(36).replace(/\./g,"");

    const hashValue = value => sha256.hmac(secretKey, value)

    const errors = {
        USER_ALREADY_EXISTS: "user_already_exists",
        USER_NOT_FOUND: "user_not_found"
    };

    return {
        errors: errors,

        resetDeviceId: () => {
            contextStore.setCustomerId(null);
            contextStore.setCustomerIdHash(null);
            contextStore.setEmail(null);
            contextStore.setDeviceId(uniqueId())
        },

        register: async email => {
            const user = await backendApi.getUser(email);
            if (user) {
                throw new Error(errors.USER_ALREADY_EXISTS);
            }

            const customerId = hashValue(email);

            contextStore.setCustomerId(customerId)
            contextStore.setCustomerIdHash(hashValue(customerId))
            const contextDTO = contextStore.get();
            const requestId = uniqueId();

            await backendApi.createUser(
                {
                    customer_id: customerId,
                    email: email,
                    store: contextDTO.store,
                    currency: contextDTO.currency,
                    environment: contextDTO.environment,
                    price_list: contextDTO.price_list,
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "customer_registration",
                    data: {
                        request_id: requestId
                    }
                }
            )
        },

        login: async (email) => {
            const user = await backendApi.getUser(email);
            if (!user) {
                throw new Error(errors.USER_NOT_FOUND);
            }

            contextStore.setCustomerId(user.customer_id);
            contextStore.setCustomerIdHash(hashValue(user.customer_id));
            contextStore.setEmail(email);

            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "login"
                }
            )
        },

        logout: async () => {
            contextStore.setCustomerId(null);
            contextStore.setCustomerIdHash(null);
            contextStore.setEmail(null);

            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "logout"
                }
            )
        },

        pageView: async url => {
            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "pageview",
                    data: {
                        url: url
                    }
                }
            )
        },

        productView: async sku => {
            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "product_view",
                    data: {
                        sku: sku
                    }
                }
            )
        },

        customEvent: async event => {
            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "custom_event",
                    data: {
                        event: event
                    }
                }
            )
        },

        checkoutEvent: async (step, option) => {
            const contextDTO = contextStore.get();

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "checkout",
                    data: {
                        step: step,
                        option: option,
                    }
                }
            )
        },

        addToCart: async (sku, name, price, image, url) => {
            if (!contextStore.getCart()) {
                contextStore.createCart(uniqueId(), contextStore.currency);
            }

            contextStore.addCartItem(sku, name, price, image, url);
            const cart = contextStore.getCart();

            const contextDTO = contextStore.get();
            const requestId = uniqueId();

            await backendApi.updateCart(
                {
                    cart_id: cart.cart_id,
                    customer_id: contextDTO.customer_id || null,
                    email: contextDTO.email || null,
                    store: contextDTO.store || null,
                    currency: contextDTO.currency || null,
                    total: cart.total,
                    items: cart.items.map(item => ({
                        name: item.name,
                        sku: item.sku,
                        price: item.price,
                        quantity: item.quantity,
                        image: item.image,
                        url: item.url,
                    }))
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "add_to_cart",
                    data: {
                        cart_hash: hashValue(cart.cart_id),
                        sku: sku,
                        request_id: requestId
                    }
                }
            )
        },

        removeFromCart: async (sku) => {
            contextStore.removeCartItem(sku);
            const cart = contextStore.getCart();
            const contextDTO = contextStore.get();
            const requestId = uniqueId();

            await backendApi.updateCart(
                {
                    cart_id: cart.cart_id,
                    customer_id: contextDTO.customer_id || null,
                    email: contextDTO.email || null,
                    store: contextDTO.store || null,
                    currency: contextDTO.currency || null,
                    total: cart.total,
                    items: cart.items.map(item => ({
                        name: item.name,
                        sku: item.sku,
                        price: item.price,
                        quantity: item.quantity,
                        image: item.image,
                        url: item.url,
                    }))
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "remove_from_cart",
                    data: {
                        cart_hash: hashValue(cart.cart_id),
                        sku: sku,
                        request_id: requestId
                    }
                }
            )
        },

        addToWishlist: async sku => {
            if (!contextStore.getWishlist()) {
                contextStore.createWishlist(uniqueId());
            }

            contextStore.addWishlistItem(sku);
            const wishlist = contextStore.getWishlist();

            const contextDTO = contextStore.get();
            const requestId = uniqueId();

            await backendApi.updateWishlist(
                {
                    wishlist_id: wishlist.wishlist_id,
                    customer_id: contextDTO.customer_id || null,
                    items: wishlist.items.map(item => ({sku: item.sku}))
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "add_to_wishlist",
                    data: {
                        wishlist_hash: hashValue(wishlist.wishlist_id),
                        sku: sku,
                        request_id: requestId
                    }
                }
            )
        },

        removeFromWishlist: async sku => {
            contextStore.removeWishlistItem(sku);
            const wishlist = contextStore.getWishlist();

            const contextDTO = contextStore.get();
            const requestId = uniqueId();

            await backendApi.updateWishlist(
                {
                    wishlist_id: wishlist.wishlist_id,
                    customer_id: contextDTO.customer_id || null,
                    items: wishlist.items.map(item => ({sku: item.sku}))
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "remove_from_wishlist",
                    data: {
                        wishlist_hash: hashValue(wishlist.wishlist_id),
                        sku: sku,
                        request_id: requestId
                    }
                }
            )
        },

        createOrder: async() => {
            let cart = contextStore.getCart();
            if (!cart) {
                return;
            }

            const contextDTO = contextStore.get();
            const orderId = uniqueId();
            const requestId = uniqueId();

            await backendApi.createOrder(
                {
                    order_id: orderId,
                    status: "pending",
                    customer_id: contextDTO.customer_id || null,
                    email: contextDTO.email || null,
                    store: contextDTO.store || null,
                    currency: contextDTO.currency || null,
                    total: cart.total,
                    is_valid: true,
                    items: cart.items.map(item => ({
                        sku: item.sku,
                        name: item.name,
                        price: item.price,
                        quantity: item.quantity,
                    })),
                },
                requestId
            )

            await frontendApi.createActivity(
                contextDTO.device_id,
                contextToActivityData(contextDTO),
                {
                    type: "sale",
                    data: {
                        order_id_hash: hashValue(orderId),
                        request_id: requestId
                    }
                }
            )

            contextStore.removeCart();
        },

        createCurrency: async code => {
            await backendApi.createCurrency(code)
        },

        subscribeOnPushNotification: async applicationId => {
            const result = await frontendApi.getWebPushApplicationConfig(applicationId);

            const contextDTO = contextStore.get();
            const vapidPublicKey = result.vapid_public_key;
            if (!vapidPublicKey) {
                return;
            }

            if (!window.navigator || !window.navigator.serviceWorker) {
                return;
            }

            if (!window.Notification) {
                return;
            }

            window
                .navigator
                .serviceWorker
                .register('/push-notification-service-worker.js', {scope: '/'})
                .catch(e => console.log(e))

            navigator
                .serviceWorker
                .ready
                .then(registration => {
                    registration.active.postMessage(
                        JSON.stringify({
                            type: 'init_recommend_environment_context',
                            payload: {
                                api_host: apiHost,
                                account_id: accountId,
                                device_id: contextDTO.device_id,
                            }
                        })
                    );

                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: vapidPublicKey
                    })
                })
                .then(subscription => frontendApi.subscribeOnWebPushChannel(
                    contextDTO.device_id,
                    applicationId,
                    subscription.toJSON()
                ))
                .catch(e => console.log(e))
        }
    }

}