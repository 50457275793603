const context = (
	localStorageItem,
	clickedElementSelector,
	contextMenuSelector,
	activeClassNameClickedElement,
	activeClassNameContextMenu,
	closeElementSelector,
	contextCountryId,
	contextLanguageId,
	contextCountryOptionsId,
	contextLanguageOptionsId,
	activeClassOptions,
	activeClassOption
) => {
	const clickedElement = document.querySelector(clickedElementSelector);
	const contextMenu = document.querySelector(contextMenuSelector);
	const closeELement = contextMenu.querySelector(closeElementSelector);
	const contextCountry = contextMenu.querySelector(contextCountryId);
	const contextLanguage = contextMenu.querySelector(contextLanguageId);
	const contextCountryOptions = contextMenu.querySelector(
		contextCountryOptionsId
	);
	const contextLanguageOptions = contextMenu.querySelector(
		contextLanguageOptionsId
	);

	if (
		!clickedElement ||
		!contextMenu ||
		!closeELement ||
		!contextCountry ||
		!contextLanguage ||
		!contextCountryOptions ||
		!contextLanguageOptions
	) {
		return;
	}

	const localStorageObject = localStorage.getItem(localStorageItem)
		? JSON.parse(localStorage.getItem(localStorageItem))
		: undefined;

	if (localStorageObject) {
		const spanLanguage = contextLanguage.querySelector("[data-language]");
		spanLanguage.dataset.language = localStorageObject.language;
		spanLanguage.innerHTML = contextLanguageOptions.querySelector(
			`div[data-language="${localStorageObject.language}"]`
		).innerHTML;
		contextLanguageOptions.querySelectorAll("div").forEach((div) => {
			if (localStorageObject.language === div.dataset.language) {
				div.classList.add(activeClassOption);
			} else {
				div.classList.remove(activeClassOption);
			}
		});

		const spanCountry = contextCountry.querySelector("[data-country]");
		spanCountry.dataset.country = localStorageObject.country.toLowerCase();
		spanCountry.innerHTML = contextCountryOptions.querySelector(
			`div[data-country="${localStorageObject.country.toLowerCase()}"] span`
		).innerHTML;
		contextCountry
			.querySelector("use")
			.setAttribute(
				"href",
				`#svgs-flags--flag-${spanCountry.dataset.country}`
			);
		contextCountryOptions.querySelectorAll("div").forEach((div) => {
			if (
				localStorageObject.country.toLowerCase() === div.dataset.country
			) {
				div.classList.add(activeClassOption);
			} else {
				div.classList.remove(activeClassOption);
			}
		});
	}

	clickedElement.addEventListener("click", () => {
		clickedElement.classList.toggle(activeClassNameClickedElement);
		contextMenu.classList.toggle(activeClassNameContextMenu);
	});

	closeELement.addEventListener("click", () => {
		clickedElement.classList.remove(activeClassNameClickedElement);
		contextMenu.classList.remove(activeClassNameContextMenu);
	});

	contextCountry.addEventListener("click", () => {
		contextLanguageOptions.classList.remove(activeClassOptions);
		contextCountryOptions.classList.toggle(activeClassOptions);
	});

	contextLanguage.addEventListener("click", () => {
		contextLanguageOptions.classList.toggle(activeClassOptions);
		contextCountryOptions.classList.remove(activeClassOptions);
	});

	contextLanguageOptions.addEventListener("click", (e) => {
		if (e.target && e.target.closest("[data-language]")) {
			localStorage.setItem(
				localStorageItem,
				JSON.stringify({
					...JSON.parse(localStorage.getItem(localStorageItem)),
					language:
						e.target.closest("[data-language]").dataset.language,
				})
			);
			window.location.reload();
		}
	});

	contextCountryOptions.addEventListener("click", (e) => {
		if (e.target && e.target.closest("[data-country]")) {
			localStorage.setItem(
				localStorageItem,
				JSON.stringify({
					...JSON.parse(localStorage.getItem(localStorageItem)),
					country: e.target
						.closest("[data-country]")
						.dataset.country.toUpperCase(),
				})
			);
			window.location.reload();
		}
	});
};

export default context;
