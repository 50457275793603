const DEMO_HERO = "demo_hero";
const DEMO_HERO_BG = "demo_hero_bg";
const DEMO_HERO_BTN_TEXT = "demo_hero_button-text";
const DEMO_HERO_BTN_TEXT_COLOR = "demo_hero_button-text-color";
const DEMO_HERO_BTN_BG = "demo_hero_button-bg";

const DEMO_SHOPPING = "demo_shopping";
const DEMO_SHOPPING_CARD_TEXT = "demo_shopping_list-card-text";
const DEMO_SHOPPING_CARD_LINK = "demo_shopping_list-card-link";
const DEMO_SHOPPING_CARD_IMG = "demo_shopping_list-card-img";

const DEMO_DESIGNERS = "demo_designers";

const DEMO_LATEST_HISTORIES = "latest_histories";
const DEMO_LATEST_HISTORIES_TITLE = "latest_histories_title";
const DEMO_LATEST_HISTORIES_IMAGE = "latest_histories_image";
const DEMO_LATEST_HISTORIES_LIST = "histories_list";
const DEMO_LATEST_HISTORIES_LIST_ITEM = "history_link";
const DEMO_LATEST_HISTORIES_LIST_IMAGE = "history_image";
const DEMO_LATEST_HISTORIES_LIST_NAME = "history_name";

const convertStringToHTML = function (str) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(str, 'text/html');
	return doc.body.children[0];
 };

const buildBlocks = (blocksToRender, selectorDropzone, changeId) => {
	const dropzone = document.querySelector(selectorDropzone);
	dropzone.dataset.changeId = changeId;

	const copiedBlockAdd = dropzone.querySelector(".block-add");
	dropzone.innerHTML = "";

	if (!blocksToRender.length) {
		const newBlockAdd = copiedBlockAdd.cloneNode(true);
		newBlockAdd.dataset.position = 0;
		dropzone.append(newBlockAdd);
		dropzone.dataset.changeId = changeId;
	}

	blocksToRender.forEach((block, index) => {
		const dataStructure = block.content_structure;
		const blockToCopy = document
			.querySelector(`[data-content-structure-render=${dataStructure}]`)
			.cloneNode(true);
		blockToCopy.dataset.contentStructureRender = "";
		blockToCopy.classList.remove("hidden");
		blockToCopy.querySelectorAll("a[data-page]").forEach((link) => {
			link.removeAttribute("href");
		});

		if (block.content_structure === DEMO_DESIGNERS) {
			Object.entries(block.data).map(([key, value]) => {
				if (Array.isArray(value)) {
					const list = blockToCopy.querySelector(`[data-${key}]`);
					let listItems;
					listItems = list.querySelectorAll("img");
					value.forEach((text, indexList) => {
						listItems[indexList].src = Object.values(text)[0];
						listItems[indexList].alt = "";
					});
				} else {
					const el = blockToCopy.querySelector(`[data-${key}]`);
					el.innerHTML = value;
				}
			});
		} else if (block.content_structure === DEMO_HERO) {
			Object.entries(block.data).map(([key, value]) => {
				if (typeof value === "object") {
					const el = blockToCopy.querySelector(`[data-${key}]`);

					Object.entries(value).forEach(([valKey, val]) => {
						switch (valKey) {
							case DEMO_HERO_BTN_BG:
								el.style.backgroundColor = val;
								break;
							case DEMO_HERO_BTN_TEXT:
								el.innerHTML = val;
								break;
							case DEMO_HERO_BTN_TEXT_COLOR:
								el.style.color = val;
								break;
						}
					});
				} else {
					const el = blockToCopy.querySelector(`[data-${key}]`);
					if (key === DEMO_HERO_BG) {
						blockToCopy.style.background = `content-box url("${value}")`;
					} else {
						el.innerHTML = value;
					}
				}
			});
		} else if (block.content_structure === DEMO_SHOPPING) {
			Object.values(block.data).forEach((value) => {
				value.forEach((card, cardIndex) => {
					Object.entries(card).forEach(([cardKey, cardVal]) => {
						const cards = blockToCopy.querySelectorAll(
							`[data-${cardKey}]`
						);

						Object.entries(cardVal).forEach(
							([propKey, propVal]) => {
								const el = cards[cardIndex].querySelector(
									`[data-${propKey}]`
								);
								switch (propKey) {
									case DEMO_SHOPPING_CARD_TEXT:
										el.innerHTML = propVal;
										break;
									case DEMO_SHOPPING_CARD_IMG:
										el.src = propVal;
										el.alt = "";
										break;
									case DEMO_SHOPPING_CARD_LINK:
										el.href = propVal;
										break;
								}
							}
						);
					});
				});
			});
		} else if (block.content_structure === DEMO_LATEST_HISTORIES) {
			Object.entries(block.data).map(([key, value]) => {
				const el = blockToCopy.querySelector(`[data-${key}]`);
				switch (key) {
					case DEMO_LATEST_HISTORIES_TITLE:
						el.innerHTML = value;
						break;
					case DEMO_LATEST_HISTORIES_IMAGE:
						el.src = value;
						el.alt = "latest histories image";
						break;
					case DEMO_LATEST_HISTORIES_LIST:
						const card = el.querySelector(`[data-${DEMO_LATEST_HISTORIES_LIST_ITEM}]`).cloneNode(true)
						el.innerHTML = "";
						value.map((value) => {
							const currentCard = card.cloneNode(true);
							const image = currentCard.querySelector(`[data-${DEMO_LATEST_HISTORIES_LIST_IMAGE}]`);
							const name = currentCard.querySelector(`[data-${DEMO_LATEST_HISTORIES_LIST_NAME}]`);
							const text = currentCard.querySelector('.container');
							text.remove();
							
							currentCard.href = value.history_link;
							image.src = value.history_image;
							image.alt = "";
							name.innerHTML = value.history_name;
							const richText = convertStringToHTML(value.history_text)
							currentCard.append(richText);
							
							el.append(currentCard);
						})
						break;
				}
			});
		}

		const newBlockAdd = copiedBlockAdd.cloneNode(true);
		newBlockAdd.dataset.position = index;
		blockToCopy.dataset.index = index;

		dropzone.append(newBlockAdd);
		dropzone.append(blockToCopy);

		if (index === blocksToRender.length - 1) {
			const newBlockAdd = copiedBlockAdd.cloneNode(true);
			newBlockAdd.dataset.position = index + 1;
			dropzone.append(newBlockAdd);
		}
	});
};

export default buildBlocks;
