const page = ({ selectorPage, scope, options, type, operationType }) => {
	const links = document.querySelectorAll(selectorPage);
	links.forEach((link) => {
		link.addEventListener("click", (e) => {
			e.preventDefault();
			window.parent.postMessage(
				JSON.stringify({
					scope,
					type,
					id: options.currentId,
					payload: {
						type: operationType,
						data: {
							url: link.href,
						},
					},
				}),
				"*"
			);
			options.currentType = operationType;
		});
	});
};

export default page;
