import edit from "./modules/edit";
import dropdown from "./modules/dropdown";
import add from "./modules/add";
import buildBlocks from "./modules/buildBlocks";
import page from "./modules/page";
import context from "./modules/context";
import events from "./modules/events";
import cart from "./modules/cart";

import { commandHandler } from "./modules/tracking/command/commandHandler";
import { api as frontendApi } from "./modules/tracking/api/frontend";
import { api as backendApi } from "./modules/tracking/api/backend";
import { contextStore } from "./modules/tracking/contextStore";

const SESSION_PERSISTED_EDITOR_STATE = 'persistedEditorState';
const LOCAL_STORAGE_ITEM = "experience-demo";
const API_HOST = localStorage.getItem('api_host') ?? "https://api.recommend.pro";

const initConfig = (defaultConfig) => {
	const queryParams = new URLSearchParams(window.location.search)
	const base64Config = queryParams.get('init_config')
	if (base64Config) {
		const config = JSON.parse(atob(decodeURIComponent(base64Config)));
		if (config
			&& config.account_id
			&& config.api_key
			&& config.secret_key
		) {
			const credentials = {
				account_id: config.account_id,
				api_key: config.api_key,
				secret_key: config.secret_key,
			};

			localStorage.setItem('credentials', JSON.stringify(credentials))

			return credentials
		}
	}

	const credentials = JSON.parse(localStorage.getItem('credentials'));
	if (credentials
		&& credentials.account_id
		&& credentials.api_key
		&& credentials.secret_key
	) {
		return {
			account_id: credentials.account_id,
			api_key: credentials.api_key,
			secret_key: credentials.secret_key,
		}
	}

	return defaultConfig;
}

const config = initConfig({
	account_id: "ecad3b10bd1a90bc",
	api_key: "NjJmNjI1OWFkMDEzZjUyNjlkNDhlMDEwOjJlZHJQU21tWmdzS3NDOHhPdDVUb29MRnVKYlFnZWYrWlhOVlIyTTZVYUNrR1Y2ckZ0WDYyOHc4UHEzTHZHOWJjaGhTVVE4RVgwQXhBemJYYTVidFRVUURJWm1qSUVoUlNrMURkNGJMaVFuNUhOQTBIUTZOT05XVHNRekRGREwwbXlFejJmc2dPVDJrY2V6OTFLZHkwTVkwT0Rxb2pmeldjYXpGSHVCbis5TT0=",
	secret_key: "B4AIkBQpFnm78VOrYkZNguDTRWMNbnfgLEq6IKHW2jMKt7ID9sTD3i+QzfM6KoI/USUwZnUWy5YkD6uPdAOsNiIQuariTlp2YYbMMEt1sS3zFAyXbNpMRfADjqMvGXxw7hnnknm5bpRdT9vKBmK2btxKreIvG/ZADH0fG5G02pY=",
});

const url = new URL(window.location);
url.searchParams.delete('init_config');
window.history.replaceState({}, '', url);

const ACCOUNT_ID = config.account_id;
const API_KEY = config.api_key;
const SECRET_KEY = config.secret_key;

const deviceId = Math.random().toString(36).replace(/\./g,"");
const INIT_LOCAL_STORAGE_STATE = {
	device_id: deviceId,
	customer_id_hash: null,
	store: "default",
	currency: "GBP",
	environment: null,
	price_list: "default",
	url: "https://experience-demo.recommend.pro",
	language: "en",
	country: "GB",
};

if (!contextStore.isExists()) {
	contextStore.init({
		device_id: deviceId,
		customer_id_hash: null,
		store: "default",
		currency: "GBP",
		environment: null,
		price_list: "default",
		language: "en",
		country: "GB",
	});
}

const CURRENCIES = ["GBP", "USD"];

const frontendApiHandler = frontendApi(API_HOST, ACCOUNT_ID);
const backendApiHandler = backendApi(API_HOST, ACCOUNT_ID, API_KEY);

const handler = commandHandler(
	contextStore,
	frontendApiHandler,
	backendApiHandler,
	SECRET_KEY,
	ACCOUNT_ID,
	API_HOST
);

CURRENCIES.forEach((code) =>  handler.createCurrency(code).catch((e) => {}));

const EXPERIENCE_MANAGER_MESSAGE_TYPE = "experience_manager";
const RESPONSE_TYPE = "response";
const REQUEST_TYPE = "request";
const TYPE_PING = "ping";
const TYPE_AUTH = 'auth';
const TYPE_INIT = "init";
const TYPE_VIEWPORT = "set_viewport";
const TYPE_MODE = "set_mode";
const TYPE_APPLY_CHANGES = "apply_changes";
const TYPE_CREATE_CHANGE = "create_change";
const TYPE_CREATE_BLOCK = "json_content_data";
const TYPE_ADD_BLOCK = "append_to_json_content_data";
const TYPE_REMOVE_BLOCK = "remove_from_json_content_data";
const TYPE_EDIT_BLOCK = "edit_json_content_data";
const TYPE_REPLACE_BLOCK = "replace_json_content_data";
const TYPE_MOVE_UP_BLOCK = "move_up_json_content_data";
const TYPE_MOVE_DOWN_BLOCK = "move_down_json_content_data";
const TYPE_UPDATE_CHANGE = "update_change";
const TYPE_CHANGE_PAGE = "change_page";
const TYPE_SET_PAGE = 'set_page';

let options = {
	currentType: TYPE_PING,
	currentId: 0,
	currentDropzoneSelector: "",
};

// true - edit, false - overview
let mode = false;


const SELECTOR_BLOCKS_EDIT = "[data-content-structure-render]";
const SELECTOR_BLOCKS_ADD = ".block-add";
const SELECTORS_DROPZONE = [
	".dropzone-0",
	".dropzone-1",
	".dropzone-2",
	".dropzone-3",
];
const SELECTOR_PAGE = "[data-page]";
window.addEventListener("DOMContentLoaded", () => {
	"use strict";

	const persistedEditorState = sessionStorage.getItem(SESSION_PERSISTED_EDITOR_STATE) ? JSON.parse(sessionStorage.getItem(SESSION_PERSISTED_EDITOR_STATE)) : '';
	if (persistedEditorState) {
		sessionStorage.removeItem(SESSION_PERSISTED_EDITOR_STATE);
		options = {...persistedEditorState.options};
		mode = persistedEditorState.mode;
		SELECTORS_DROPZONE.forEach((selector) => {
			edit(
				mode,
				selector,
				SELECTOR_BLOCKS_EDIT,
				SELECTOR_BLOCKS_ADD
			);
		});
		window.parent.postMessage(
			JSON.stringify({
				scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
				type: RESPONSE_TYPE,
				id: persistedEditorState.id,
				payload: {
					success: true,
				},
			}),
			"*"
		);
		options.currentId = +options.currentId + 1;
	
	}
	const header = document.getElementById("header");
	const main = document.getElementById("main");
	const loader = document.getElementById("loader");

	cart('cart', contextStore.getCart, handler.removeFromCart);
	cart('wishlist', contextStore.getWishlist, handler.removeFromWishlist, 'Wishlist is empty');

	const transformChanges = (changes) => {
		return changes.reduce((prev, curr) => {
			const currentChange = curr.changes
				.map((op) => {
					const operation = {
						change_id: null,
						el: {
							type: op.data.el.type,
							selector: op.data.el.val,
						},
						blocks: op.data.contents.map((content, index) => {
							return {
								id: index,
								content_structure: content.type,
								data: content.data,
							};
						}),
					};
					const foundOperation = prev.find(
						(item) => item.el.selector === operation.el.selector
					);
					if (foundOperation) {
						foundOperation.blocks = [
							...foundOperation.blocks,
							...operation.blocks,
						];
						return null;
					}
					return operation;
				})
				.filter((op) => op);
			return [...prev, ...currentChange];
		}, []);
	};

	const fetchData = async () => {
		try {
			if (!localStorage.getItem(LOCAL_STORAGE_ITEM)) {
				localStorage.setItem(
					LOCAL_STORAGE_ITEM,
					JSON.stringify({
						...INIT_LOCAL_STORAGE_STATE,
						country: document
							.querySelector("#contextCountry")
							.querySelector("span")
							.dataset.country.toUpperCase(),
						language: document
							.querySelector("#contextLanguage")
							.querySelector("span").dataset.language,
						url: window.location.href,
					})
				);
			} else {
				localStorage.setItem(
					LOCAL_STORAGE_ITEM,
					JSON.stringify({
						...JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM)),
						url: window.location.href,
					})
				);
			}

			context(
				LOCAL_STORAGE_ITEM,
				".header__nav-icon",
				".context-menu",
				"header__nav-icon_active",
				"context-menu_active",
				".context-menu__close",
				"#contextCountry",
				"#contextLanguage",
				"#contextCountryOptions",
				"#contextLanguageOptions",
				"context-menu__item-options_active",
				"context-menu__item-option_active"
			);

			return frontendApiHandler.getExperienceChanges(
				contextStore,
				window.location.href
			);
		} catch (err) {}
	};

	if (window === window.top) {
		fetchData().then((result) => {
			if (Array.isArray(result.variants)) {
				transformChanges(result.variants).forEach((variant) => {
					buildBlocks(
						variant.blocks,
						variant.el.selector,
						variant.change_id
					);
				});
			}

			header.classList.remove("hidden");
			main.classList.remove("hidden");
			loader.style.display = "none";
		});
		handler.pageView(window.location.href)
		events(handler);
		return;
	}

	header.classList.remove("hidden");
	main.classList.remove("hidden");
	loader.style.display = "none";

	
	window.addEventListener("message", (event) => {
		let data;
		try {
			data = JSON.parse(event.data);
		} catch (e) {
			return;
		}

		if (
			!data ||
			data.scope !== EXPERIENCE_MANAGER_MESSAGE_TYPE ||
			!data.payload
		) {
			return;
		}

		if (data.payload.type === TYPE_PING) {
			options = {
				currentType: TYPE_PING,
				currentId: 0,
				currentDropzoneSelector: "",
			};
		}


		if (data.payload.type) {
			switch (data.payload.type) {
				case TYPE_PING:
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: RESPONSE_TYPE,
							id: data.id,
							payload: {
								success: true,
							},
						}),
						"*"
					);
					options.currentType = TYPE_PING;
					options.currentId = +options.currentId + 1;
					break;
				case TYPE_INIT:
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: RESPONSE_TYPE,
							id: data.id,
							payload: {
								success: true,
								data: {
									context: {
										options: [
											{
												code: "country",
												label: "Country",
												values: {
													ua: "Ukraine",
													us: "United States of America",
													gb: "United Kingdom",
													ca: "Canada",
													fr: "France",
													au: "Australia",
													nz: "New Zealand",
												},
												multiselect: true,
											},
											{
												code: "language",
												label: "Language",
												values: {
													uk: "Ukrainian",
													en: "English",
													fr: "French",
												},
												multiselect: true,
											},
											{
												code: "baseline",
												label: "Baseline",
												values: {
													eu: "Europe",
												},
												multiselect: false,
											},
										],
										query_param:
											"_experience_editor_context",
									},
								},
							},
						}),
						"*"
					);
					options.currentType = TYPE_INIT;
					options.currentId = +options.currentId + 1;
					break;
				case TYPE_MODE:
					mode = data.payload.data.mode !== "overview";
					SELECTORS_DROPZONE.forEach((selector) => {
						edit(
							mode,
							selector,
							SELECTOR_BLOCKS_EDIT,
							SELECTOR_BLOCKS_ADD
						);
					});
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: RESPONSE_TYPE,
							id: data.id,
							payload: {
								success: true,
							},
						}),
						"*"
					);
					options.currentType = TYPE_MODE;
					options.currentId = +options.currentId + 1;
					break;
				case TYPE_VIEWPORT:
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: RESPONSE_TYPE,
							id: data.id,
							payload: {
								success: true,
							},
						}),
						"*"
					);
					options.currentType = TYPE_VIEWPORT;
					options.currentId = +options.currentId + 1;
					break;
				case TYPE_APPLY_CHANGES:
					SELECTORS_DROPZONE.forEach((selector) => {
						const existedChange = data.payload.data.changes.find(
							(change) => change.data.el.val === selector
						);
						if (existedChange) {
							buildBlocks(
								existedChange.data.contents,
								existedChange.data.el.val,
								existedChange.id
							);
							add({
								dropzoneSelector: existedChange.data.el.val,
								selectorBlockAdd: SELECTOR_BLOCKS_ADD,
								scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
								type: REQUEST_TYPE,
								operations: {
									createType: TYPE_CREATE_BLOCK,
									addType: TYPE_ADD_BLOCK,
								},
								options,
							});
							edit(
								mode,
								existedChange.data.el.val,
								SELECTOR_BLOCKS_EDIT,
								SELECTOR_BLOCKS_ADD
							);
						} else {
							buildBlocks([], selector, null);
							add({
								dropzoneSelector: selector,
								selectorBlockAdd: SELECTOR_BLOCKS_ADD,
								scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
								type: REQUEST_TYPE,
								operations: {
									createType: TYPE_CREATE_BLOCK,
									addType: TYPE_ADD_BLOCK,
								},
								options,
							});
							edit(
								mode,
								selector,
								SELECTOR_BLOCKS_EDIT,
								SELECTOR_BLOCKS_ADD
							);
						}
					});
					
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: RESPONSE_TYPE,
							id: data.id,
							payload: {
								success: true,
							},
						}),
						"*"
					);
					options.currentType = TYPE_APPLY_CHANGES;
					options.currentId = +options.currentId + 1;
					
					
					break;
				case TYPE_SET_PAGE:		
					sessionStorage.setItem(SESSION_PERSISTED_EDITOR_STATE, JSON.stringify({options, mode, id: data.id}));
					window.location.replace(data.payload.data.url);
					break;
			}
			return;
		} else {
			switch (options.currentType) {
				case TYPE_CREATE_CHANGE:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_ADD_BLOCK;
					const dropzone = document.querySelector(
						options.currentDropzoneSelector
					);
				
					dropzone.dataset.changeId = data.payload.data.change_id;
					window.parent.postMessage(
						JSON.stringify({
							scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
							type: REQUEST_TYPE,
							id: options.currentId,
							payload: {
								type: TYPE_ADD_BLOCK,
								data: {
									change_id: dropzone.dataset.changeId,
									content_position: 0,
									content_structures:
										dropzone.dataset.contentStructure.split(
											","
										),
								},
							},
						}),
						"*"
					);
					break;
				case TYPE_ADD_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_APPLY_CHANGES;
					break;
				case TYPE_REMOVE_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_REMOVE_BLOCK;
					break;
				case TYPE_EDIT_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_EDIT_BLOCK;
					break;
				case TYPE_REPLACE_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_REPLACE_BLOCK;
					break;
				case TYPE_MOVE_UP_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_MOVE_UP_BLOCK;
					break;
				case TYPE_MOVE_DOWN_BLOCK:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_MOVE_DOWN_BLOCK;
					break;
				case TYPE_CHANGE_PAGE:
					options.currentId = +options.currentId + 1;
					options.currentType = TYPE_CHANGE_PAGE;
					break;
			}
		}
	});

	page({
		selectorPage: SELECTOR_PAGE,
		scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
		type: REQUEST_TYPE,
		operationType: TYPE_CHANGE_PAGE,
		options,
	});
	dropdown({
		scope: EXPERIENCE_MANAGER_MESSAGE_TYPE,
		type: REQUEST_TYPE,
		operations: {
			operationDelete: TYPE_REMOVE_BLOCK,
			operationEdit: TYPE_EDIT_BLOCK,
			operationReplace: TYPE_REPLACE_BLOCK,
			operationMoveUp: TYPE_MOVE_UP_BLOCK,
			operationMoveDown: TYPE_MOVE_DOWN_BLOCK,
		},
		options,
	});
});
