const dropdown = ({ scope, type, operations, options }) => {
	const dropdown = document.querySelector(".dropdown");
	const dropdownItems = dropdown.children;

	const {
		operationDelete,
		operationEdit,
		operationReplace,
		operationMoveUp,
		operationMoveDown,
	} = operations;

	Array.from(dropdownItems).forEach((item) => {
		switch (item.dataset.action) {
			case "remove":
				item.addEventListener("click", () => {
					const block = document
						.querySelector(dropdown.dataset.dropzone)
						.querySelector(
							`[data-index="${dropdown.dataset.active}"]`
						);
					window.parent.postMessage(
						JSON.stringify({
							scope,
							type,
							id: options.currentId,
							payload: {
								type: operationDelete,
								data: {
									change_id:
										block.parentElement.dataset.changeId,
									content_id: dropdown.dataset.active,
								},
							},
						}),
						"*"
					);
					options.currentType = operationDelete;
					// block.dataset.removed = true;
					// block.classList.add("block-edit_removed");

					// const divOverlay = block.querySelector(".overlay");
					// divOverlay.insertAdjacentHTML(
					// 	"afterbegin",
					// 	"<span class='overlay__text'>Block removed</span>"
					// );

					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
				});
				break;
			case "edit":
				item.addEventListener("click", () => {
					const block = document
						.querySelector(dropdown.dataset.dropzone)
						.querySelector(
							`[data-index="${dropdown.dataset.active}"]`
						);
					window.parent.postMessage(
						JSON.stringify({
							scope,
							type,
							id: options.currentId,
							payload: {
								type: operationEdit,
								data: {
									change_id:
										block.parentElement.dataset.changeId,
									content_id: dropdown.dataset.active,
								},
							},
						}),
						"*"
					);
					options.currentType = operationEdit;
					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
					block.classList.remove("block-edit_active");
				});
				break;
			case "replace":
				item.addEventListener("click", () => {
					const block = document
						.querySelector(dropdown.dataset.dropzone)
						.querySelector(
							`[data-index="${dropdown.dataset.active}"]`
						);
					window.parent.postMessage(
						JSON.stringify({
							scope,
							type,
							id: options.currentId,
							payload: {
								type: operationReplace,
								data: {
									change_id:
										block.parentElement.dataset.changeId,
									content_id: dropdown.dataset.active,
									content_structures:
										block.parentElement.dataset.contentStructure.split(
											","
										),
								},
							},
						}),
						"*"
					);
					options.currentType = operationReplace;
					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
					block.classList.remove("block-edit_active");
				});
				break;
			case "move-up":
				item.addEventListener("click", () => {
					const block = document
						.querySelector(dropdown.dataset.dropzone)
						.querySelector(
							`[data-index="${dropdown.dataset.active}"]`
						);
					window.parent.postMessage(
						JSON.stringify({
							scope,
							type,
							id: options.currentId,
							payload: {
								type: operationMoveUp,
								data: {
									change_id:
										block.parentElement.dataset.changeId,
									content_id: dropdown.dataset.active,
								},
							},
						}),
						"*"
					);
					options.currentType = operationMoveUp;
					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
				});
				break;
			case "move-down":
				item.addEventListener("click", () => {
					const block = document
						.querySelector(dropdown.dataset.dropzone)
						.querySelector(
							`[data-index="${dropdown.dataset.active}"]`
						);
					window.parent.postMessage(
						JSON.stringify({
							scope,
							type,
							id: options.currentId,
							payload: {
								type: operationMoveDown,
								data: {
									change_id:
										block.parentElement.dataset.changeId,
									content_id: dropdown.dataset.active,
								},
							},
						}),
						"*"
					);
					options.currentType = operationMoveDown;
					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
				});
				break;
			// case "restore":
			// 	item.addEventListener("click", () => {
			// 		const block = document.querySelector(
			// 			`[data-index="${dropdown.dataset.active}"]`
			// 		);
			// 		block.dataset.removed = "";
			// 		block.classList.remove("block-edit_removed");

			// 		const divOverlay = block.querySelector(".overlay");
			// 		divOverlay.innerHTML = "";

			// 		dropdown.classList.remove("dropdown_active");
			// 	});
			// 	break;
		}
	});
};

export default dropdown;
