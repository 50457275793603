const add = ({
	dropzoneSelector,
	selectorBlockAdd,
	scope,
	type,
	operations: { createType, addType },
	options,
}) => {
	const dropzone = document.querySelector(dropzoneSelector);
	const blocks = dropzone.querySelectorAll(selectorBlockAdd);

	blocks?.forEach((block) => {
		const onClick = () => {
			if (dropzone.dataset.changeId === "null") {
				options.currentType = "create_change";
				options.currentDropzoneSelector = dropzoneSelector;
				window.parent.postMessage(
					JSON.stringify({
						scope,
						type,
						id: options.currentId,
						payload: {
							type: "create_change",
							data: {
								type: createType,
								data: {
									el: {
										type: "css",
										val: dropzoneSelector,
									},
									contents: [],
								},
							},
						},
					}),
					"*"
				);
			} else {
				window.parent.postMessage(
					JSON.stringify({
						scope,
						type,
						id: options.currentId,
						payload: {
							type: addType,
							data: {
								change_id: dropzone.dataset.changeId,
								content_position: +block.dataset.position,
								content_structures:
									dropzone.dataset.contentStructure.split(
										","
									),
							},
						},
					}),
					"*"
				);
				options.currentType = addType;
			}
		};

		block.onclick = onClick;
	});
};

export default add;
