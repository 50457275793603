import {baseUrl, getMethod, json, jsonRequest, postMethod, resultJsonBody, validate, wrap} from "./fetch";

export const api = (host, accountId) => {
    const middlewares = [
        json,
        validate,
        resultJsonBody,
        baseUrl(`${host}/v3/${accountId}`),
        jsonRequest
    ];

    const http = {
        get: wrap([...middlewares, getMethod]),
        post: wrap([...middlewares, postMethod]),
    }

    return {
        createActivity: async (deviceId, config, event) => {
            await http.post(
                `device/${deviceId}/activity`,
                {
                    body: {
                        ...config,
                        activity: [event]
                    }
                },
            );
        },

        createActivities: async (deviceId, config, events) => {
            await http.post(
                `device/${deviceId}/activity`,
                {
                    body: {
                        ...config,
                        activity: events
                    }
                },
            );
        },

        getExperienceChanges: async (context, url, customEnvironmentContext) => {
            const contextDTO = context.get();

            return await http.post(
                `experience_manager/view`,
                {
                    body: {
                        device_id: contextDTO.device_id,
                        customer_id_hash: contextDTO.customer_id_hash,
                        store: contextDTO.store,
                        currency: contextDTO.currency,
                        environment: contextDTO.environment,
                        price_list: contextDTO.price_list,
                        url: url,
                        language: contextDTO.language,
                        country: contextDTO.country,
                        custom_context: customEnvironmentContext
                    }
                },
            );
        },

        getWebPushApplicationConfig: async applicationId => {
            return http.get(
                `messaging/channel/push/web/application/${applicationId}`,
                {},
            );
        },

        subscribeOnWebPushChannel: async (deviceId, applicationId, token) => {
            return http.post(
                `messaging/channel/push/web/subscription/${deviceId}`,
                {
                    body: {
                        "token": {
                            "value": token.endpoint,
                            "keys": {
                                "p256dh": token.keys.p256dh,
                                "auth": token.keys.auth
                            }
                        },
                        "application_id": applicationId,
                        "subscription_status": "subscribed"
                    }
                },
            );
        },
    }
}