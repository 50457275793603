const createModal = (id, title, body) => {
  const modal = document.createElement("div");

  modal.classList = "micromodal";
  modal.id = id;
  modal.setAttribute("aria-hidden", "true");

  const headerTitle = title
    ? `<h2 class="modal__title" id="${id}-title">
    ${title}
    </h2> `
    : "";
  const modalOverlay = `<div class="micromodal__overlay" data-micromodal-close></div>`;
  const modalBody = `
        <div class="micromodal__container" role="dialog" aria-modal="true" aria-labelledby="${id}-title">
            <header class="modal__header">
                ${headerTitle}
                <button class="modal__close-btn" aria-label="Close modal" data-micromodal-close>X</button>
            </header>
            ${body}
        </div>
    `;

  modal.innerHTML = `${modalOverlay}${modalBody}`;

  return modal;
};

export default createModal;
