import {baseUrl, json, jsonRequest, postMethod, getMethod, resultJsonBody, validate, wrap} from "./fetch";

export const api = (host, accountId, key) => {
    const middlewares = [
        json,
        validate,
        resultJsonBody,
        baseUrl(`${host}/v3/${accountId}`),
        jsonRequest
    ];

    const http = {
        get: wrap([...middlewares, getMethod]),
        post: wrap([...middlewares, postMethod]),
    }

    const authenticate = (() => {
        let token = undefined;

        const getToken = async () => {
            if (token && token.expire_at - Math.floor(Date.now() / 1000) > 300) {
                return token.key;
            }

            const result = await http.post(
                'authenticate',
                {
                    body: {
                        key: key
                    }
                }
            );

            token = {
                key: result.auth.token,
                expire_at: result.auth.expire_date
            }

            return result.auth.token;
        }

        return async (input, init, next) => {
            return next(
                input,
                {
                    ...init,
                    headers: {
                        ...(init.headers || {}),
                        authorization: `Bearer ${await getToken()}`
                    }
                }
            )
        }
    })();

    const httpWithAuthentication = {
        get: wrap([authenticate], http.get),
        post: wrap([authenticate], http.post),
    }

    return {
        createCurrency: async (code) => await httpWithAuthentication.post(`currency/${code}`, {body: {}}),

        updateWishlist: async (wishlistDTO, requestId) => {
            await httpWithAuthentication.post(
                'wishlist/batch',
                {
                    body: {
                        data: [
                            {
                                action: "upsert_update",
                                data: {
                                    wishlist_id: wishlistDTO.wishlist_id,
                                    customer_id: wishlistDTO.customer_id,
                                    items: wishlistDTO.items.map(item => ({sku: item.sku})),
                                    request_id: requestId,
                                }
                            }
                        ]
                    },
                },
            );
        },

        updateCart: async (cartDTO, requestId) => {
            await httpWithAuthentication.post(
                'cart/batch',
                {
                    body: {
                        data: [
                            {
                                action: "upsert_update",
                                data: {
                                    cart_id: cartDTO.cart_id,
                                    customer_id: cartDTO.customer_id,
                                    currency: cartDTO.currency,
                                    base_currency: cartDTO.currency,
                                    total: cartDTO.total,
                                    base_total: cartDTO.total,
                                    items: cartDTO.items.map(item => ({
                                        name: item.name,
                                        sku: item.sku,
                                        price: item.price,
                                        base_price: item.price,
                                        quantity: item.quantity,
                                        url: item.url,
                                        image: item.image,
                                    })),
                                    request_id: requestId
                                }
                            }
                        ]
                    },
                },
            );
        },

        createOrder: async (orderDTO, requestId) => {
            const data = {
                order_id: orderDTO.order_id,
                status: orderDTO.status,
                customer_id: orderDTO.customer_id,
                currency: orderDTO.currency,
                base_currency: orderDTO.currency,
                total: orderDTO.total,
                base_total: orderDTO.total,
                is_valid: true,
                items: orderDTO.items.map(item => ({
                    sku: item.sku,
                    name: item.name,
                    quantity: item.quantity,
                    cost: item.price,
                    base_cost: item.price
                })),
                store_code: orderDTO.store,
                request_id: requestId
            };

            if (orderDTO.email !== null) {
                data.email = orderDTO.email;
            }

            await httpWithAuthentication.post(
                'order/batch',
                {
                    body: {
                        data: [
                            {
                                action: "upsert_update",
                                data: data
                            }
                        ]
                    },
                },
            );
        },

        getUser: async (email) => {
            const result = await httpWithAuthentication.post(
                'contact/search',
                {
                    body: {
                        emails: [email]
                    },
                },
            );

            if (!result || !result.data || result.data.length === 0) {
                return null;
            }

            const user = result.data[0];
            return {
                customer_id: user.customer_id,
                email: user.email,
            }
        },

        createUser: async (registrationUserDTO, requestId) => {
            const data = {
                customer_id: registrationUserDTO.customer_id,
                email: registrationUserDTO.email,
                request_id: requestId || null
            };

            const contextData = ['store', 'currency', 'environment', 'price_list'].reduce(
                (acc, value) => {
                    if (registrationUserDTO[value] !== null) {
                        acc[value] = registrationUserDTO[value]
                    }

                    return acc;
                },
                data
            )

            await httpWithAuthentication.post(
                'contact/batch/customer_id',
                {
                    body: {
                        data: [
                            {
                                action: "upsert_update",
                                data: contextData
                            }
                        ]
                    },
                },
            );
        },
    }
}