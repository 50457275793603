import MicroModal from "micromodal";
import createModal from "./createModal";

const createActionDropdown = (ACTIONS) => {
    let button;
    const actionItems = ACTIONS
        .map((action, index) => {
            if (index === 0) {
                button = `<span
                    class="context-menu__item-text"
                    data-action="${action.code}"
                    >${action.title}</span>`;
            }
            return ` <div
                class="context-menu__item-option ${
                index === 0 && "context-menu__item-option_active"
            }"
                data-action="${action.code}"
            >
              ${action.title}
            </div>`;
        })
        .join("");

    return `
  <div class="context-menu__item">
      <div
          class="context-menu__item-wrapper"
          id="contextActions"
      >
          ${button}
          <span
              class="context-menu__item-arrow"
          ></span>
      </div>
      <div
          class="context-menu__item-options"
          id="contextActionsOptions"
      >
          ${actionItems}
      </div>
  </div>
`;
};

const createInput = (label="", id, placeholder = "", val = "") => (`<div class="modal__input-wrapper"><p>${label}</p><label class="text-field__label">
        <input class="text-field__input" type="text" name="${id}" id="${id}" placeholder="${placeholder}" value="${val}">
    </label></div>`);

const updateInputVisibility = (params) => {
    document.querySelectorAll('.modal__input-wrapper').forEach(input => {
        input.setAttribute('style', 'display: none')
    })
    params.forEach(param => {
        document.getElementById(param).closest('.modal__input-wrapper').removeAttribute('style');
    })
}

const removeMessage = () => {
    document.querySelector('.modal__message') && document.querySelector('.modal__message').remove();
}

const createError = (text = 'Some error occurred') => {
    const error = document.createElement("p");

    error.className = "modal__message modal__error";
    error.innerText = text;

    return error
}

const createSuccessMessage = (text = 'Successfully sent') => {
    const error = document.createElement("p");

    error.className = "modal__message modal__success ";
    error.innerText = text;

    return error
}

const events = (eventsList) => {
    const body = document.getElementsByTagName("body")[0];

    const ACTIONS = [
        {
            code: "addToCart",
            title: "Add to cart",
            params: ["sku", "name", "price", "image", "url"],
            handler: async (sku, name, price, image, url) => {
                await eventsList.addToCart(sku, name, parseFloat(price), image, url)
            }
        },
        {
            code: "addToWishlist",
            title: "Add to wishlist",
            params: ["sku"],
            handler: async (sku) => {
                await eventsList.addToWishlist(sku)
            }
        },
        {
            code: "createOrder",
            title: "Create order",
            params: [],
            handler: async () => {
                await eventsList.createOrder()
            }
        },
        {
            code: "pageView",
            title: "Page view",
            params: [],
            handler: async () => {
                await eventsList.pageView(window.location.href)
            }
        },
        {
            code: "productView",
            title: "Product view",
            params: ["sku"],
            handler: async (sku) => {
                await eventsList.productView(sku)
            }
        },
        {
            code: "customEvent",
            title: "Custom Event",
            params: ["custom_event"],
            handler: async (custom_event) => {
                await eventsList.customEvent(custom_event)
            }
        },
        {
            code: "checkout",
            title: "Checkout Event",
            params: ["step", "option"],
            handler: async (step, option) => {
                await eventsList.checkoutEvent(step, option)
            }
        },
        {
            code: "register",
            title: "Sign up",
            params: ["email"],
            handler: async (email) => {
                try {
                    await eventsList.register(email)
                } catch (error) {
                    if (error.message === eventsList.errors.USER_ALREADY_EXISTS) {
                        throw {
                            type: "user_error",
                            message: "User already exists"
                        }
                    }

                    throw new Error();
                }
            }
        },
        {
            code: "login",
            title: "Sign in",
            params: ["email"],
            handler: async (email) => {
                try {
                    await eventsList.login(email)
                } catch (error) {
                    if (error.message === eventsList.errors.USER_NOT_FOUND) {
                        throw {
                            type: "user_error",
                            message: "User not found"
                        }
                    }

                    throw new Error();
                }
            }
        },
        {
            code: "logout",
            title: "Sign out",
            params: [],
            handler: async () => {
                await eventsList.logout()
            }
        },
        {
            code: "resetDevice",
            title: "Reset device id",
            params: [],
            handler: async () => {
                await eventsList.resetDeviceId()
            }
        },
        {
            code: "pushNotificationSubscribe",
            title: "Subscribe on Push Notifications",
            params: ["applicationId"],
            handler: async (applicationId) => {
                await eventsList.subscribeOnPushNotification(applicationId)
            }
        }
    ]

    const getActionByCode = code => ACTIONS.find(action => action.code === code);

    const dropdown = createActionDropdown(ACTIONS);
    const skuInput = createInput("SKU", "sku", "SKU");
    const nameInput = createInput("Name", "name", "Name");
    const priceInput = createInput("Price", "price", "Price");
    const imageInput = createInput("Image", "image", "example.com/image.png");
    const urlInput = createInput("URL", "url", "example.com");
    const codeInput = createInput("Code", "code", "Code");
    const customEvent = createInput("Event", "custom_event", "Event");
    const checkoutStep = createInput("Step", "step", "Step");
    const checkoutOption = createInput("Option", "option", "Option");
    const expIdInput = createInput("Experiment Id", "experimentId", "Experiment Id");
    const varIdInput = createInput("Variant Id", "variantId", "Experiment Id");
    const changeIdInput = createInput("Change Ids", "changeIds", "id 1, id 2...");
    const email = createInput("Email", "email", "Email");
    const applicationId = createInput("Application ID", "applicationId", "Push application ID");
    const submitButton = `<footer class="modal__footer"><button class="modal__btn" id="submitAction">Submit</button></footer>`;

    const modalBody = `<form id="events-form" class="modal__form">
                      ${dropdown} 
                      <div class="modal__input-grid">
                        ${skuInput} 
                        ${nameInput}
                        ${priceInput}
                        ${imageInput}
                        ${urlInput}
                        ${codeInput}
                        ${customEvent}
                        ${checkoutStep}
                        ${checkoutOption}
                        ${expIdInput}
                        ${varIdInput}
                        ${changeIdInput}
                        ${email}
                        ${applicationId}
                      </div>
                      ${submitButton}
                    </form>`;
    const modal = createModal("events", "Create event", modalBody);

    body.appendChild(modal);

    if(Object.keys(ACTIONS).length > 0){
        updateInputVisibility(ACTIONS[0].params);
    }

    const button = document.createElement("button");

    button.innerText = "create event";
    button.classList = "btn btn";
    button.style = `color: #1b1b1a; 
                    font-weight: 700;
                    text-transform: uppercase; 
                    padding: 15px 60px;
                    border-radius: 5px;
                    position: fixed;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #1b1b1a;
                    -webkit-box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
                    box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
                    bottom: 30px;
                    right: 30px;`;
    button.setAttribute("data-micromodal-trigger", "events");
    body.appendChild(button);

    MicroModal.init({});

    const contextActions = document.getElementById("contextActions");
    const contextActionsOptions = document.getElementById(
        "contextActionsOptions"
    );

    contextActionsOptions.addEventListener("click", (e) => {
        if (e.target && e.target.closest("[data-action]")) {
            removeMessage();

            contextActionsOptions
                .querySelectorAll(".context-menu__item-option")
                .forEach((item) => {
                    item.classList.remove("context-menu__item-option_active");
                });
            e.target.classList.toggle("context-menu__item-option_active");
            contextActions.querySelector(".context-menu__item-text").innerText =
                e.target.innerText;
            contextActions
                .querySelector(".context-menu__item-text")
                .setAttribute("data-action", e.target.getAttribute("data-action"));
            contextActionsOptions.classList.remove(
                "context-menu__item-options_active"
            );

            const params = getActionByCode(e.target.getAttribute("data-action")).params;
            updateInputVisibility(params)
        }
    });

    document.getElementById("events").addEventListener("click", (e) => {
        if (
            e.target.closest("#contextActions") ||
            e.target.id == "contextActions"
        ) {
            contextActionsOptions.classList.toggle(
                "context-menu__item-options_active"
            );
            return;
        }

        if (
            !e.target.classList.contains("context-menu__item") ||
            !e.target.closest(".context-menu__item")
        ) {
            contextActionsOptions.classList.remove(
                "context-menu__item-options_active"
            );

        }
    });

    const submitAction = document.getElementById("submitAction");

    const form = document.getElementById("events-form");

    form.addEventListener("submit", (e) => {
        e.preventDefault();
        const form = document.getElementById("events-form");
        const formData = new FormData(form);
        const data = {};

        removeMessage();
        try {
            const action = contextActions
                .querySelector(".context-menu__item-text")
                .getAttribute("data-action")
                .trim();

            const actionConfig = getActionByCode(action);
            const params = actionConfig.params;

            for (let [key, value] of formData) {
                params.forEach((param) => {
                    if (key == param) {
                        if(value.trim().length <= 0){
                            throw new Error()
                        }
                        data[param] = value;
                    }
                });
            }

            const paramList = Object.values(data).length > 0 ? [...Object.values(data)] : [];
            actionConfig.handler(...paramList).then(response => {
                form.reset();
                submitAction.parentNode.insertBefore(createSuccessMessage(), submitAction);
                //document.querySelector('.micromodal__overlay').click();
            }).catch(err => {
                if (err && err.type === 'user_error' && err.message) {
                    submitAction.parentNode.insertBefore(createError(err.message), submitAction);
                } else {
                    submitAction.parentNode.insertBefore(createError(), submitAction);
                }
            })
        } catch (err) {
            submitAction.parentNode.insertBefore(createError(), submitAction);
        }
    });
};

export default events;
