export const contextStore = (() => {
    const handlers = {};
    const set = (itemKey, value) => {
        const stringObject = localStorage.getItem(key);
        const object = stringObject ? JSON.parse(stringObject) : {};
        object[itemKey] = value;

        localStorage.setItem(key, JSON.stringify(object));

        Object.keys(handlers).forEach(key => {
            handlers[key]();
        })
    }
    const key = 'experience-demo';

    return {
        isExists: () => {
            return !!localStorage.getItem(key);
        },

        get: () => {
            const stringObject = localStorage.getItem(key);
            if (!stringObject) {
                return {};
            }

            const object = JSON.parse(stringObject);

            return typeof object === 'object' && object !== null ? object : {};
        },

        init: (contextDTO) => {
            const contextData = [
                'device_id',
                'customer_id_hash',
                'store',
                'currency',
                'environment',
                'price_list',
                'language',
                'country',
            ].reduce((acc, value) => {
                if (typeof contextDTO[value] !== 'undefined') {
                    acc[value] = contextDTO[value]
                }

                return acc;
            }, {})

            localStorage.setItem(key, JSON.stringify({...contextStore.get(), ...contextData}))
        },

        setDeviceId: deviceId => set('device_id', deviceId),

        setCustomerId: id => set("customer_id", id),

        setCustomerIdHash: hash => set("customer_id_hash", hash),

        setEmail: email => set("email", email),

        setStore: code => set("store", code),

        setCurrency: code => set("currency", code),

        setLanguage: code => set("language", code),

        setCountry: code => set("country", code),

        setEnvironment: code => set("environment", code),

        setPriceList: code => set("price_list", code),

        removeCart: () => set('cart', null),

        getCart: () => contextStore.get().cart || null,

        createCart: (cartId, currency) => {
            set('cart', {
                cart_id: cartId,
                currency: currency,
                items: []
            })
        },

        addCartItem: (sku, name, price, image, url) => {
            let cart = contextStore.getCart() || {}

            const index = cart.items.findIndex(item => item.sku === sku)

            if (index !== -1) {
                ++cart.items[index].quantity
            } else {
                cart.items.push({
                    name: name,
                    sku: sku,
                    price: price,
                    quantity: 1,
                    image: image,
                    url: url,
                })
            }

            cart.total = cart.items.reduce((acc, item) => acc + item.price * item.quantity, 0);

            set('cart', cart)
        },

        removeCartItem: (sku) => {
            let cart = contextStore.getCart() || {}

            cart.items = cart.items.filter(item => item.sku !== sku);
            cart.total = cart.items.reduce((acc, item) => acc + item.price * item.quantity, 0);

            set('cart', cart);
        },

        getWishlist: () => contextStore.get().wishlist || null,

        createWishlist: (id) => {
            set('wishlist', {
                wishlist_id: id,
                items: []
            })
        },

        addWishlistItem: sku => {
            let wishlist = contextStore.getWishlist() || {}

            const index = wishlist.items.findIndex(item => item.sku === sku)

            if (index === -1) {
                wishlist.items.push({sku: sku})
            }

            set('wishlist', wishlist)
        },

        removeWishlistItem: (sku) => {
            let wishlist = contextStore.getWishlist() || {}

            wishlist.items = wishlist.items.filter(item => item.sku !== sku);

            set('wishlist', wishlist);
        },

        subscribe: (key, handler) => {
            if (!key || !handler) return;
            handlers[key] = handler;
        }
    }
})();