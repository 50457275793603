export const baseUrl = (base) => (url, init, next) => next(`${base}/${url}`, init)

export const jsonRequest = (url, init, next) => {
    return next(
        url,
        {
            ...init,
            body: JSON.stringify(init.body),
            headers: {
                ...(init.headers || {}),
                "Content-Type": "application/json"
            }
        }
    )
}

export const validate = async (url, init, next) => {
    const body = await next(url, init)
    if (!body || !body.success) {
        throw new Error('error')
    }

    return body;
};

export const resultJsonBody = async (url, init, next) => {
    const body = await next(url, init)

    return body.result;
};

export const json = async (url, init, next) => {
    const response = await next(url, init);
    return await response.json();
}

export const postMethod = async (url, init, next) => await next(url, {...init, method: 'POST'});

export const getMethod = async (url, init, next) => await next(url, {...init, method: 'GET'});

export const wrap = (middlewares, innerHandler) => {
    let handler = async (url, init) => await fetch(url, init);

    if (innerHandler) {
        handler = innerHandler;
    }

    middlewares.forEach(middleware => {
        handler = (handler => (url, init) => middleware(url, init, handler))(handler)
    });

    return handler;
}