import tippy from 'tippy.js';
import {contextStore} from "./tracking/contextStore";

const Cart = (id, getAction, removeAction, emptyText = 'Shopping Bag is empty') => {
    const cartEl = document.querySelector(`#${id}`);

    const updateCount = () => {
        const cart = getAction();
        const count = cart && cart.items ? cart.items.length : 0;
        cartEl.querySelector('.header__shopping-info-text').innerHTML = count + ' ITEMS';
    }

    const getContent = () => {
        const cart = getAction();

        if (!cart || !cart.items.length) {
            return `<span class="cart__empty-text">${emptyText}</span>`;
        }
        const items = cart.items.map((item, index) => {
            return `<div class="cart__item">
                    <span class="cart__item-name">${item.name || item.sku}</span> 
                    <span class="cart__item-price">${item.price ? item.price + ' $' : ''}</span>
                    <svg class="cart__item-remove" data-sku="${item.sku}" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L13 13" stroke="#181B1C"/>
                        <path d="M1 13L13 1" stroke="#181B1C"/>
                    </svg>
                </div>`;
        })

        return `<div class="cart">${items.join('')}</div>`;
    }

    updateCount();
    contextStore.subscribe(id, updateCount)

    const tippyInstance = tippy(cartEl, {
        onShow(instance) {
            instance.setContent(getContent())
        },
        onAfterUpdate(instance, partialProps) {
            instance.popper.querySelectorAll('.cart__item-remove').forEach(item => {
                item.removeEventListener('click', removeItem);
                item.addEventListener('click', removeItem);
            });
        }, trigger: 'click', interactive: true, theme: 'light-border', allowHTML: true, hideOnClick: false
    })

    const removeItem = (event) => {
        if (event.currentTarget) {
            removeAction(event.currentTarget.dataset.sku).then(() => {
                tippyInstance.setContent(getContent())
            }).catch((e) => {
                console.error(e)
            });
        }
    }

    document.addEventListener('click', event => {
        if (!tippyInstance.popper.contains(event.target) && !tippyInstance.reference.contains(event.target)) {
            tippyInstance.hide()
        }
    })

}

export default Cart;