const edit = (
	mode,
	dropzoneSelector,
	selectorBlockEdit,
	selectorBlockAdd = ".block-add"
) => {
	const dropzone = document.querySelector(dropzoneSelector);
	const blocks = dropzone.querySelectorAll(selectorBlockEdit);
	const blocksAdd = dropzone.querySelectorAll(selectorBlockAdd);

	if (mode) {
		blocksAdd.forEach((blockAdd) => {
			blockAdd.classList.add("block-add_active");
		});
	} else {
		blocksAdd.forEach((blockAdd) => {
			blockAdd.classList.remove("block-add_active");
		});
	}

	if (!blocks.length) {
		return;
	}

	blocks.forEach((block, index) => {
		if (mode) {
			const div = document.createElement("div");
			div.classList.add("overlay");
			block.classList.add("block-edit");
			block.append(div);

			div.addEventListener("click", () => {
				const dropdown = document.querySelector(".dropdown");
				if (dropdown.classList.contains("dropdown_active")) {
					dropdown.classList.remove("dropdown_active");
					dropdown.style.left = "";
					dropdown.style.top = "";
				}
				block.classList.toggle("block-edit_active");
			});

			div.addEventListener("contextmenu", (e) => {
				e.preventDefault();
				const dropdown = document.querySelector(".dropdown");
				dropdown.classList.add("dropdown_active");
				dropdown.dataset.active = block.dataset.index;
				dropdown.dataset.dropzone = dropzoneSelector;
				block.classList.add("block-edit_active");

				dropdown.style.left = e.pageX + "px";
				dropdown.style.top = e.pageY + "px";

				if (!index) {
					dropdown.querySelector(
						'[data-action="move-up"]'
					).style.display = "none";
				} else {
					dropdown.querySelector(
						'[data-action="move-up"]'
					).style.display = "";
				}

				if (index === blocks.length - 1) {
					dropdown.querySelector(
						'[data-action="move-down"]'
					).style.display = "none";
				} else {
					dropdown.querySelector(
						'[data-action="move-down"]'
					).style.display = "";
				}
			});
		}

		// overview
		if (!mode) {
			const div = block.querySelector(".overlay");
			const dropdown = document.querySelector(".dropdown");
			dropdown.classList.remove("dropdown_active");
			dropdown.style.left = "";
			dropdown.style.top = "";

			if (div) {
				div.remove();
			}
		}
	});
};

export default edit;
